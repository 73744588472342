import React from "react";

// import heroImg from '../../assets/media/hero-img.png';
import heroImg from "../../assets/media/img1.png";

import herodesktopImg from "../../assets/media/img0.png";

import heroVideo from "../../assets/media/hero-video.mp4";

const Hero = ({ isMdScreen }) => (
  <section id="hero" className="hero-section">
    <div className="content-wrap">
      <div className="grid-wrap desktop-only">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="text-wrap">
        <h1>Streamline Your Tax Reports in Minutes with AI!</h1>
        <div className="lines-wrap">
          <div className="line">
            <h4>AITaxBot</h4>
          </div>
          <div className="line">
            <h4>
              Our automated system scans for every possible deduction, from
              taxes and slippage to gas fees, rugs, and any losses with a
              negative impact on your crypto portfolio. With our comprehensive
              AI-driven analysis, you get a complete, detailed tax report,
              ensuring nothing is missed. Simplify your tax season and maximize
              your deductions effortlessly!
            </h4>
          </div>
        </div>
      </div>
      <div className="img-wrap desktop">
        <video src={heroVideo} autoPlay loop muted playsInline />
      </div>
    </div>
  </section>
);

export default Hero;
