import {Box, Grid, Slider, Stack, Typography} from "@mui/material";
import React, {useState} from "react";

const TaxCalculatorSlider = () => {
    const [value, setValue] = useState(256); // Default position of the slider

    const getTotalValue = (e) => {
        const totalValue = e * 10000; // 1M$ per point on the slider
        return `$${totalValue.toLocaleString()} Saved`; // Add the dollar sign
    };

    const getRevShareProfit = (e) => {
        const totalValue = e * 10000; // Calculate the total revenue
        const tenPercent = totalValue * 0.10; // 10% of the total value
        return tenPercent.toLocaleString();
    };

    return (<section id="calc" className="tax-calculator">
        <div className="content-wrap">
            <div className="grid-wrap desktop-only">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="text-wrap">
                <h2 className="animation">BuyBack & Burn Mechanism</h2>

                {/*
          <!--
          <div className="items-wrap inv_calc_slider">
            <Slider
              aria-label="Restricted values"
              defaultValue={256}
              min={1}
              max={1000} // You can adjust the max value if needed
              valueLabelDisplay="auto"
              valueLabelFormat={(e) => getTotalValue(e)}
              color="#ffff"
              onChange={(e, newValue) => setValue(newValue)} // Update the state when slider is moved
            />
          </div>

          <Grid container mt={4}>
            <Grid item md={12} xs={12} justifyContent="start" flex={1}>
              <Box
                className="item animation"
                justifyContent="center"
                width="100%"
                flex={1}
                display="flex"
              >
                <h4 className="opacity-7">RevShare Profit: $ {getRevShareProfit(value)}</h4>
              </Box>
            </Grid>
          </Grid>
          */}

                <Grid container spacing={2} mt={4}>
                    <Grid item md={8} sm={12} xs={12} justifyContent="start" flex={1}>
                        <Stack direction="column" spacing={2} pt={2}>
                            <p className="item animation opacity-7">
                                The <strong>$AITAX</strong> token offers both simplicity and powerful utility. By
                                holding <strong>$AITAX</strong>,
                                you not
                                only gain access to free tax reports, but also benefit from a robust buyback and burn
                                strategy fueled by real revenue generated through the platform. Profits from the
                                AITaxBot ecosystem are distributed as follows:
                            </p>
                            <p className="item animation opacity-7">
                                50% for Buyback & Burn: Reducing supply
                                50% for Reinvestment: Focused on furthering development, marketing, and team support to
                                strengthen and grow the ecosystem.
                                Hold <strong>$AITAX</strong> and be part of a sustainable, value-driven economy!
                            </p>
                        </Stack>
                    </Grid>

                    <Grid item sm={12} xs={12} md={4}>
                        <Box pt={2} className="links-wrap animation tax_gap">
                            <a
                                href="https://docs.aitax.bot/token/usdaitax"
                                className="card"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    height: "20%"
                                }}
                            >
                                <h4>More Info</h4>
                                <h5>+</h5>
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    </section>);
};

export default TaxCalculatorSlider;
