import {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './components/Home';

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {width, height};
}
const App = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const appHeightHandle = () => {
        document.querySelector('body').style.setProperty('--app-height', window.innerHeight + 'px');
    }

    appHeightHandle();

    window.addEventListener('resize', () => {
        if (window.innerWidth < 1200) {
            appHeightHandle();
        }
    });

    useEffect(() => {
        const handleResize = () => setWindowDimensions(getWindowDimensions());

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Home windowDimensions={windowDimensions}/>}/>
        </Routes>
    )
};

export default App;
